<template>
  <div class="card border-0">
    <div class="row justify-content-between">
      <div class="col-auto">
        <h5 class="e-text-red">
          Objective Evidence
        </h5>
      </div>
      <div class="col-auto text-right" v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.create')">
        <button class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2" data-toggle="modal" data-target="#newObjectiveEvidence">
          <font-awesome-icon icon="plus" class="mr-2"/>
          Add Objective Evidence
        </button>
      </div>
    </div>
    <table class="table table-sm table-bordered text-center">
      <thead>
      <tr>
        <th>O.E #</th>
        <th>Description</th>
        <th>Rectified on the spot</th>
        <th>Corrective Action Request</th>
        <th>Category</th>
        <th>Ref to SMS/Regulator</th>
        <th>Action</th>
      </tr>
      </thead>
      <draggable
        :key="'oe_list_for_' + $route.params.ncr_type"
        v-if="$route.params.ncr_type === 'ncr'"
        tag="tbody"
        v-model="nonConformityType[$route.params.type][$attrs['active-ncr-index']].objective_evidences"
        @end="onEnd"
      >
        <tr
          class="list-data no-select cursor-grab"
          v-for="(oe, index) in objectiveEvidences"
          :class="[
            oe.id === parseInt($route.params.oe_id) ? 'selected-row' : '',
            index === mouseHoldActiveRowIndex ? 'mouse-active-row' : ''
          ]"
          @dblclick="openOrChangeIndex(oe.id, index)"
          @mousedown="setActiveBackground('add', index)"
          @mouseup="setActiveBackground('remove')"
        >
          <td class="align-middle">{{ index + 1}}</td>
          <td class="align-middle text-left">{{ oe.description | stripHtmlTags }}</td>
          <td class="align-middle">{{ oe.rectified === 1 ? 'YES' : 'NO' }}</td>
          <td class="align-middle text-left">{{ oe.ca_request | stripHtmlTags }}</td>
          <td class="align-middle">{{ oe.category?.name }}</td>
          <td class="align-middle">{{ oe.reference }}</td>
          <td class="align-middle">
            <div class="dropdown dropright">
              <button
                id="actionDropdown"
                aria-expanded="false"
                aria-haspopup="true"
                class="mr-2 btn btn-sm btn-secondary force-white-all"
                data-toggle="dropdown"
              >
                <font-awesome-icon icon="cogs"/>
              </button>
              <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                <button class="dropdown-item text-secondary font-weight-bolder"
                        v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.view')"
                        @click="openOrChangeIndex(oe.id, index)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                        v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.delete')"
                        @click="deleteSelected(oe.id)"
                >
                  <font-awesome-icon icon="trash" class="force-danger-all"/>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>

      </draggable>
      <draggable
        :key="'oe_list_for_' + $route.params.ncr_type"
        v-if="$route.params.ncr_type === 'obs'"
        tag="tbody"
        v-model="observationNoteType[$route.params.type][$attrs['active-ncr-index']].objective_evidences"
        @end="onEnd"
      >
        <tr
          class="list-data no-select cursor-grab"
          v-for="(oe, index) in objectiveEvidences"
          :class="[
            oe.id === parseInt($route.params.oe_id) ? 'selected-row' : '',
            index === mouseHoldActiveRowIndex ? 'mouse-active-row' : ''
          ]"
          @dblclick="openOrChangeIndex(oe.id, index)"
          @mousedown="setActiveBackground('add', index)"
          @mouseup="setActiveBackground('remove')"
        >
          <td class="align-middle">{{ index + 1}}</td>
          <td class="align-middle text-left" style="word-break: break-word">{{ oe.description | stripHtmlTags }}</td>
          <td class="align-middle">{{ oe.rectified === 1 ? 'YES' : 'NO' }}</td>
          <td class="align-middle text-left" style="word-break: break-word">{{ oe.ca_request | stripHtmlTags }}</td>
          <td class="align-middle">{{ oe.category?.name }}</td>
          <td class="align-middle">{{ oe.reference }}</td>
          <td class="align-middle">
            <div class="dropdown dropright"
                 v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.view') || isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.delete')"
            >
              <button
                id="actionDropdown"
                aria-expanded="false"
                aria-haspopup="true"
                class="mr-2 btn btn-sm btn-secondary force-white-all"
                data-toggle="dropdown"
              >
                <font-awesome-icon icon="cogs"/>
              </button>
              <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                <button class="dropdown-item text-secondary font-weight-bolder"
                        @click="openOrChangeIndex(oe.id, index)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                        @click="deleteSelected(oe.id)"
                >
                  <font-awesome-icon icon="trash" class="force-danger-all"/>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>

      </draggable>
    </table>

    <template v-if="$route.params.oe_id !== 'index' && activeOeIndex !== null">
      <o-e-sub-details
        :key="'oe_' + $route.params.oe_id + '_' + activeOeIndex"
        :active-oe-index="activeOeIndex"
        :active-ncr-index="$attrs['active-ncr-index']"
        :oe-length="objectiveEvidences.length"
        @changeIndex="changeIndex"
        @close="closeDetail"
      />
    </template>

    <add-objective-evidence
      :active-ncr-index="$attrs['active-ncr-index']"
    />
  </div>
</template>


<script>

import AddObjectiveEvidence from "@/components/modules/ia/vessel/nonConformity/details/AddObjectiveEvidence.vue";
import NonConformityMixin from "@/mixins/NonConformityMixin";
import OESubDetails from "@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail/OESubDetails.vue";
import nonConformityDetail from "@/components/modules/ia/vessel/nonConformity/nonConformityDetail.vue";
import {AlertService} from "@/services/AlertService";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {mapActions} from "vuex";
import draggable from 'vuedraggable'
import _ from 'lodash';

export default {
  name : 'ObjectiveEvidenceDetail',
  data() {
    return {
      activeOeIndex : null,
      showAddOeModal : false,
      mouseHoldActiveRowIndex : null
    }
  },
  mixins : [NonConformityMixin, vueAppMixin],
  components : {nonConformityDetail, AddObjectiveEvidence, OESubDetails, draggable},
  methods : {
    ...mapActions([
      'deleteObjectiveEvidence',
      'updateOrderNumberFromList',
    ]),
    getUpdatedOrderFromList() {
      let newUpdatedOrderList = [];

      const orderNumbers = _.sortBy(this.objectiveEvidences.map(item => item.list_order))

      this.objectiveEvidences.forEach((item, index) => {
        newUpdatedOrderList.push({
          id : item.id,
          prev_list_order : item.list_order,
          list_order : orderNumbers[index]
        });
      });

      return newUpdatedOrderList;
    },
    async onEnd(event) {
      if (event.oldIndex === this.activeOeIndex) {
        this.activeOeIndex = event.newIndex;
      } else if (event.oldIndex > this.activeOeIndex && event.newIndex <= this.activeOeIndex) {
        this.activeOeIndex++;
      } else if (event.oldIndex < this.activeOeIndex && event.newIndex >= this.activeOeIndex) {
        this.activeOeIndex--;
      }
      this.setActiveBackground('remove');
      const listWithUpdatedOrder = this.getUpdatedOrderFromList();
      const updateOrderFromListResponse = await this.updateOrderNumberFromList({
        id : this.$route.params.id,
        type : this.$route.params.type,
        ncr_id : this.$route.params.ncr_id,
        module : 'objective_evidence',
        lists_orders : listWithUpdatedOrder
      });
      if (updateOrderFromListResponse.result === false) {
        return AlertService.errorAlert(updateOrderFromListResponse.message, 'UPDATING OF OBJECTIVE EVIDENCES ORDERS');
      }

      await this.getUpdatedNonConformity();
    },
    setActiveBackground(method, index) {
      if (method === 'add') {
        this.mouseHoldActiveRowIndex = index;
      } else {
        this.mouseHoldActiveRowIndex = null;
      }
    },
    openOrChangeIndex(objectiveEvidenceId, index) {
      if (this.$route.params.oe_id === 'index') {
        this.viewSelected(objectiveEvidenceId, index)
      } else {
        this.changeIndex(index);
      }

      this.scrollToNcrMainDetails('oe_details', 100, 100);
    },
    viewSelected(objectiveEvidenceId, activeOeIndex) {
      this.activeOeIndex = activeOeIndex;
      if (objectiveEvidenceId !== parseInt(this.$route.params.oe_id)) {
        this.$router.replace({name: 'OEMainDetails', params:
            {
              oe_id: this.objectiveEvidences[activeOeIndex].id,
            }
        });
      }
    },
    async deleteSelected(objectiveEvidenceId) {
      if(await AlertService.confirmDeleteAlert('Delete Objective Evidence', 'Are you sure you want to delete this Objective Evidence Record?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteObjectiveEvidence({id: objectiveEvidenceId, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              await this.successAlert('OBJECTIVE EVIDENCE DELETED SUCCESSFULLY','DELETE OBJECTIVE EVIDENCE RECORD');
              await this.getUpdatedNonConformity();

              if (this.$route.name !== 'ObjectiveEvidenceDetail') {
                await this.$router.replace({name: 'ObjectiveEvidenceDetail', params:
                    {
                      oe_id: 'index',
                    }
                });
              }
            }
          });
      }

    },
    changeIndex(newIndex) {
      this.activeOeIndex = newIndex;
      const objectiveEvidenceId = this.objectiveEvidences[newIndex].id;
      if (objectiveEvidenceId !== parseInt(this.$route.params.oe_id)) {
        this.$router.replace({name: this.$route.name, params:
            {
              oe_id: objectiveEvidenceId,
            }
        });
      }
    },
    closeDetail() {
      this.activeOeIndex = null;
      this.$router.replace({name: 'ObjectiveEvidenceDetail', params:
          {
            oe_id: 'index',
          }
      });
    }
  },
  computed : {
    objectiveEvidences() {
      const data = this.$route.params.ncr_type === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];
      return data[this.$attrs['active-ncr-index']]?.objective_evidences;
    },
  },
  created() {
    if (this.$route.params.oe_id === 'index') {
      return;
    }
    const data = this.$route.params.ncr_type === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];
    const oeIndex = data[this.$attrs['active-ncr-index']]?.objective_evidences.findIndex(oe => oe.id === parseInt(this.$route.params.oe_id));
    if (oeIndex !== -1) {
      this.activeOeIndex = oeIndex;
    }
  }
}
</script>


<style scoped>
.mouse-active-row {
  box-shadow : rgba(0, 0, 0, 0.5) 0px 0px 5px 5px;
}
</style>
