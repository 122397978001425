<template>
    <div class="card p-3 mb-3 shadow-sm" id="ncr_main_details">
      <div class="d-flex justify-content-between no-gutters">
        <div class="col-6">
          <h5 class="e-text-red">
            NCR / OBS Detail
          </h5>
        </div>
        <div class="col-auto">
          <button class="btn btn-secondary btn-xs" :disabled="currentPage === 1" @click="$emit('changeIndex', currentPage - 2)">
            <font-awesome-icon icon="arrow-left" class="force-white-all"></font-awesome-icon>
          </button>
          {{ currentPage }}
          /
          {{ conformityLength }}
          <button class="btn btn-secondary btn-xs" :disabled="currentPage === conformityLength" @click="$emit('changeIndex', currentPage)">
            <font-awesome-icon icon="arrow-right" class="force-white-all"></font-awesome-icon>
          </button>

          <button class="btn btn-xs e-btn-red ml-4" @click="$emit('close')">Close</button>

        </div>
      </div>
      <nav class="navbar navbar-expand-lg p-0" id="ModelNav">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.non-conformity.ncr-details.menu')">
              <router-link class="nav-link" :to="{
              name: 'NcrMainDetails'
            }">
                <span><span class="text-uppercase">{{ $route.params.ncr_type }}</span> Detail</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.menu')">
              <router-link
                class="nav-link white" :to="constructRouterTo('ObjectiveEvidenceDetail')">
                <span>Objective Evidence</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
      <router-view
        :key="'nav-bar' + '_' + $route.params.vessel_id + '_' + $route.params.id + '_' + $route.params.type + '_' + $route.params.ncr_id"
        :active-ncr-index="activeNcrIndex"
      ></router-view>
    </div>
</template>

<script>

import NcrMainDetails from "@/components/modules/ia/vessel/nonConformity/details/NcrMainDetails.vue";
import ObjectiveEvidenceDetail from "@/components/modules/ia/vessel/nonConformity/details/ObjectiveEvidenceDetail.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
export default {
  name : 'nonConformityDetail',
  mixins : [permissionMixin],
  components: {
    NcrMainDetails,
    ObjectiveEvidenceDetail,
  },
  props : {
    activeNcrIndex : {
      type: Number,
      default : 0
    },
    conformityLength : {
      type : Number,
      default: 0
    }
  },
  data() {
    return {
      currentPage : this.activeNcrIndex + 1
    }
  },
  methods : {
    constructRouterTo(tabName) {
        const ncrDetailsChildrenRoutes = ['OEMainDetails', 'OEAttachments'];
        return {
          name: ncrDetailsChildrenRoutes.includes(this.$route.name) === true ? this.$route.name : tabName,
          params : this.constructParams()
        }
    },
    constructParams() {
        if (this.$route.params.oe_id === undefined) {
          return {
            oe_id : 'index'
          }
        }
        return this.$route.params;
    },
  }
}
</script>

<style scoped>


.nav > .nav-link {
  padding: 8px 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #435464;
  margin-bottom: -1px;
}

.nav > .active, .nav > .nav-link:hover {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}
</style>
