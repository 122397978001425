var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card pl-2 pb-3 border-0",attrs:{"id":"oe_details"}},[_c('div',{staticClass:"d-flex no-gutters justify-content-between"},[_vm._m(0),_c('div'),_c('div',[_c('button',{staticClass:"btn btn-secondary btn-xs",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.$emit('changeIndex', _vm.currentPage - 2)}}},[_c('font-awesome-icon',{staticClass:"force-white-all",attrs:{"icon":"arrow-left"}})],1),_vm._v(" "+_vm._s(_vm.currentPage)+" / "+_vm._s(_vm.oeLength)+" "),_c('button',{staticClass:"btn btn-secondary btn-xs",attrs:{"disabled":_vm.currentPage === _vm.oeLength},on:{"click":function($event){return _vm.$emit('changeIndex', _vm.currentPage)}}},[_c('font-awesome-icon',{staticClass:"force-white-all",attrs:{"icon":"arrow-right"}})],1),_c('button',{staticClass:"btn btn-xs e-btn-red ml-4",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")])])]),_c('nav',{staticClass:"navbar navbar-expand-lg p-0",attrs:{"id":"ModelNav"}},[_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarNav"}},[_c('ul',{staticClass:"navbar-nav"},[(_vm.isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.oe-details.menu'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
                name: 'OEMainDetails'
            }}},[_c('span',[_vm._v("OE Detail and Attachments")])])],1):_vm._e(),(_vm.isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.menu'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link white",attrs:{"to":{
                  name: 'IaCompanyInstructions'
              }}},[_c('span',[_vm._v("OE Company Instructions")])])],1):_vm._e()])])]),_c('hr',{staticStyle:{"border-top":"2px solid #e7e7e7","margin-top":"-2px"}}),_c('router-view',{key:'nav-bar' + '_' + _vm.$route.params.vessel_id + '_' + _vm.$route.params.id + '_' + _vm.$route.params.type + '_' + _vm.$route.params.ncr_id + '_' + _vm.$route.params.oe_id,attrs:{"active-ncr-index":_vm.$attrs['active-ncr-index'],"active-oe-index":_vm.activeOeIndex}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('h5',{staticClass:"e-text-red"},[_vm._v(" Objective Evidence Detail ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNav","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }